import { Component, Vue } from "vue-property-decorator";
import { BusinessServiceStorage } from "@/store/business-service-storage";

@Component
export default class sendmail extends Vue{
  // ロゴ画像
  logoImagePath = null as string | null;
  
  async mounted() {
    const storage = BusinessServiceStorage.getSessionStorage();
    this.logoImagePath = storage.logoImagePath ? storage.logoImagePath : null;
  }

}
